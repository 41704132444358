var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ShiftInsightHeaderMoleculeStyled', {
    attrs: {
      "isLarge": _vm.isLarge
    }
  }, [_c('GoBackBar', {
    attrs: {
      "title": _vm.title,
      "largeTitle": true
    },
    scopedSlots: _vm._u([{
      key: "subtitle",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.recipientLabel))];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "info-wrapper"
  }, [_c('ShiftGeneralInformationMolecule', {
    attrs: {
      "shift": _vm.shiftInformation,
      "isLarge": _vm.isLarge
    }
  }), _c('SearchBar', {
    staticClass: "search",
    attrs: {
      "searchQuery": _vm.searchQuery,
      "placeHolder": 'actions.search'
    },
    on: {
      "filterShareList": function filterShareList($event) {
        return _vm.$emit('textFilter', $event);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }