var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GeneralInfoWrapper', {
    attrs: {
      "isLarge": _vm.isLarge
    }
  }, [_c('SectionWrapper', [_c('calendar-icon', {
    attrs: {
      "size": "1.5x"
    }
  }), _c('span', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.$t('shiftReportEvent.shiftGeneralInformation.date')) + ": " + _vm._s(_vm.dateFormatted))])], 1), _c('SectionWrapper', [_c('clock-icon', {
    attrs: {
      "size": "1.5x"
    }
  }), _c('span', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.$t('shiftReportEvent.shiftGeneralInformation.shiftTimePeriod')) + ": " + _vm._s(_vm.shiftTimePeriod))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }