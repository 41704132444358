var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : _c('ShiftInsightPage', [_c('ShiftInsightHeaderMolecule', {
    attrs: {
      "event": _vm.myEvent,
      "isLarge": _vm.isLarge,
      "searchQuery": _vm.searchQuery
    },
    on: {
      "textFilter": _vm.textSearch
    }
  }), _c('ShiftInsightMainOrganism', {
    attrs: {
      "event": _vm.myEvent,
      "assets": _vm.assetsCategorized,
      "isMobile": !_vm.isLarge,
      "searchQuery": _vm.searchQuery
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }