var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ShiftInsightsMainStyled', [_vm.productionAssets.length ? _c('ProductionOrganism', {
    attrs: {
      "event": _vm.event,
      "assets": _vm.productionAssets,
      "isMobile": _vm.isMobile,
      "searchQuery": _vm.searchQuery
    }
  }) : _vm._e(), _vm.drillingAssets.length ? _c('DrillingOrganism', {
    attrs: {
      "event": _vm.event,
      "assets": _vm.drillingAssets,
      "isMobile": _vm.isMobile,
      "searchQuery": _vm.searchQuery
    }
  }) : _vm._e(), _vm.auxiliaryAssets.length ? _c('AuxiliaryOrganism', {
    attrs: {
      "event": _vm.event,
      "assets": _vm.auxiliaryAssets,
      "isMobile": _vm.isMobile,
      "searchQuery": _vm.searchQuery
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }