<template>
  <GeneralInfoWrapper :isLarge="isLarge">
    <SectionWrapper>
      <calendar-icon size="1.5x" />
      <span class="content">{{ $t('shiftReportEvent.shiftGeneralInformation.date') }}: {{ dateFormatted }}</span>
    </SectionWrapper>
    <SectionWrapper>
      <clock-icon size="1.5x" />
      <span class="content">{{ $t('shiftReportEvent.shiftGeneralInformation.shiftTimePeriod') }}: {{ shiftTimePeriod }}</span>
    </SectionWrapper>
  </GeneralInfoWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'
import get from 'lodash/get'
import { CalendarIcon, ClockIcon } from 'vue-feather-icons'

const GeneralInfoWrapper = styled('div')`
  display: grid;
  gap: 25px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.atomic.textSecondary};
  box-sizing: border-box;
  padding: 15px;
  grid-area: info;
  ${({ isLarge }) => isLarge && `grid-template-columns: max-content max-content;`};
  align-items: center;
`
const SectionWrapper = styled('div')`
  display: flex;
  align-content: center;
  .content {
    margin-left: 0.5rem;
  }
`

export default {
  inject: ['uiSettings'],
  props: {
    shift: {
      type: Object,
      required: false,
    },
    isLarge: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    GeneralInfoWrapper,
    CalendarIcon,
    ClockIcon,
    SectionWrapper,
  },
  computed: {
    dateStartEnd() {
      return {
        start: DateTime.fromISO(this.shift?.startTime).setLocale(this.locale),
        end: DateTime.fromISO(this.shift?.endTime).setLocale(this.locale),
      }
    },
    dateFormatted() {
      const { start, end } = this.dateStartEnd

      const sameDay = start.hasSame(end, 'day')

      if (sameDay) {
        return start.toLocaleString(DateTime.DATE_SHORT)
      }
      return `${start.toLocaleString(DateTime.DATE_SHORT)} - ${end.toLocaleString(DateTime.DATE_SHORT)}`
    },
    reportCreationDate() {
      const dt = DateTime.fromISO(this.shift?.createdAt).setLocale(this.locale)
      return dt.toLocaleString(DateTime.TIME_SIMPLE)
    },
    shiftTimePeriod() {
      const { start, end } = this.dateStartEnd
      return `${start.toLocaleString(DateTime.TIME_SIMPLE)} - ${end.toLocaleString(DateTime.TIME_SIMPLE)}`
    },
    locale() {
      const ds = get(this.uiSettings, 'dates', 'DE_DE')
      return ds.replace('_', '-').toLowerCase()
    },
  },
}
</script>
