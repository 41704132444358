<template>
  <ShiftInsightHeaderMoleculeStyled :isLarge="isLarge">
    <GoBackBar :title="title" :largeTitle="true">
      <template v-slot:subtitle>{{ recipientLabel }}</template>
    </GoBackBar>
    <div class="info-wrapper">
      <ShiftGeneralInformationMolecule :shift="shiftInformation" :isLarge="isLarge" />
      <SearchBar :searchQuery="searchQuery" @filterShareList="$emit('textFilter', $event)" :placeHolder="'actions.search'" class="search" />
    </div>
  </ShiftInsightHeaderMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import GoBackBar from '@/components/Atomic/Atoms/GoBackBar'
import ShiftGeneralInformationMolecule from '@/components/Atomic/Molecules/ShiftGeneralInformation'
import SearchBar from '@/components/Atomic/Molecules/SearchBar'

const ShiftInsightHeaderMoleculeStyled = styled('div')`
  grid-area: header;
  display: grid;
  .info-wrapper {
    display: flex;
    align-items: end;
    justify-content: space-between;
    > .search {
      width: 15rem;
      height: 2rem;
      margin: 0.75rem;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: start;
    }
  }
  > .go-back-bar-wrapper {
    background: none;
    padding: 0;
    .large {
      ${({ isLarge }) => !isLarge && `font-size: 18px;`};
    }
  }
`

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
    isLarge: {
      type: Boolean,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  components: {
    ShiftInsightHeaderMoleculeStyled,
    GoBackBar,
    ShiftGeneralInformationMolecule,
    SearchBar,
  },
  computed: {
    title() {
      return this.$t('shiftReportEvent.titles.insights', { shiftName: this.event.shiftName })
    },
    recipientLabel() {
      return this.event?.recipient?.name || ''
    },
    shiftInformation() {
      return {
        startTime: this.event?.shiftStart,
        endTime: this.event?.shiftEnd,
        createdAt: this.event?.createdAt,
      }
    },
  },
  methods: {},
}
</script>
