<template>
  <TalpaLoaderWrapper v-if="$apollo.loading" />
  <ShiftInsightPage v-else>
    <ShiftInsightHeaderMolecule :event="myEvent" :isLarge="isLarge" @textFilter="textSearch" :searchQuery="searchQuery" />
    <ShiftInsightMainOrganism :event="myEvent" :assets="assetsCategorized" :isMobile="!isLarge" :searchQuery="searchQuery" />
  </ShiftInsightPage>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'
import { get } from 'lodash'

import { TalpaLoaderWrapper } from '@common/components'
import ShiftInsightHeaderMolecule from './ShiftInsight/ShiftInsightHeaderMolecule.vue'
import { productionAssetTypes, drillingAssetTypes, auxiliaryAssetTypes } from './ShiftInsight/AssetTypeMapper'

import ShiftInsightMainOrganism from './ShiftInsight/ShiftInsightMainOrganism.vue'

import resizeMixin from '@/mixins/resize'

import MY_EVENT_QUERY from '#/graphql/feed/myEvent.gql'

const ShiftInsightPage = styled('div')`
  position: relative;
  min-height: calc(100% - 1rem);
  display: grid;
  padding: 0.5rem;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 0.5rem;
  grid-template-areas:
    'header'
    'content';
  height: calc(100% - 1rem);
  overflow: hidden;
`

export default {
  inject: ['uiSettings'],
  mixins: [resizeMixin],
  components: {
    TalpaLoaderWrapper,
    ShiftInsightPage,
    ShiftInsightHeaderMolecule,
    ShiftInsightMainOrganism,
  },
  data() {
    return {
      myEvent: null,
      selectedUtilizationCategory: null,
      isLarge: true,
      assetTypeComponent: null,
      searchQuery: '',
    }
  },
  watch: {
    isMobile: {
      handler() {
        this.handleResize()
      },
      immediate: true,
    },
  },
  created() {},
  computed: {
    assets() {
      return this.myEvent?.assets || []
    },
    assetsCategorized() {
      const categorized = {
        productionAssets: [],
        drillingAssets: [],
        auxiliaryAssets: [],
      }
      return this.assets.reduce((acc, asset) => {
        const utilizationCategories = asset?.utilizationCategories || []
        if (utilizationCategories.length > 0) {
          if (utilizationCategories.find(f => f === 'PRODUCTION')) {
            acc.productionAssets.push(asset)
          }
          if (utilizationCategories.find(f => f === 'AUXILIARY')) {
            acc.auxiliaryAssets.push(asset)
          }
          if (utilizationCategories.find(f => f === 'DRILLING')) {
            acc.drillingAssets.push(asset)
          }
        } else {
          if (productionAssetTypes.some(type => asset.type.id === type.id)) {
            acc.productionAssets.push(asset)
          }
          if (auxiliaryAssetTypes.some(type => asset.type.id === type.id)) {
            acc.auxiliaryAssets.push(asset)
          }
          if (drillingAssetTypes.some(type => asset.type.id === type.id)) {
            acc.drillingAssets.push(asset)
          }
        }
        return acc
      }, categorized)
    },

    message() {
      const lastUpdateMillis = this.myEvent?.assetLocations.reduce((maxMillis, assetLocation) => {
        const millis = DateTime.fromISO(assetLocation.time).toMillis()
        return Math.max(maxMillis, millis)
      }, 0)
      if (lastUpdateMillis === 0) {
        return this.$t('shiftReportEvent.titles.bannerNoData')
      }
      const timestamp = DateTime.fromMillis(lastUpdateMillis).setLocale(this.locale)
      const date = timestamp.toLocaleString(DateTime.DATE_SHORT)
      const time = timestamp.toLocaleString(DateTime.TIME_SIMPLE)
      return this.$t('shiftReportEvent.titles.banner', { date: date, time: time })
    },
    locale() {
      const ds = get(this.uiSettings, 'dates', 'DE_DE')
      return ds.replace('_', '-').toLowerCase()
    },
  },
  methods: {
    handleResize() {
      this.$nextTick(() => {
        if (window.innerWidth < 1024 || this.isMobile) {
          this.isLarge = false
        } else {
          this.isLarge = true
        }
      })
    },
    textSearch(query) {
      this.searchQuery = query
    },
  },
  apollo: {
    myEvent: {
      query: MY_EVENT_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params.id,
          },
        }
      },
      skip() {
        return !this.$route?.params?.id
      },
    },
  },
}
</script>
