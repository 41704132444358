export const productionAssetTypes = [
  {
    id: 10,
    name: 'Excavator',
    symbol: 'EX',
  },
  {
    id: 12,
    name: 'Surface Dumper',
    symbol: 'SD',
  },
  {
    id: 13,
    name: 'Load Haul Dump',
    symbol: 'LHD',
  },
  {
    id: 14,
    name: 'Loader',
    symbol: 'LDR',
  },
  {
    id: 15,
    name: 'Underground Dumper',
    symbol: 'UD',
  },
  {
    id: 19,
    name: 'Highway Truck',
    symbol: 'HT',
  },
]

export const drillingAssetTypes = [
  {
    id: 11,
    name: 'Drill Rig',
    symbol: 'DR',
  },
  {
    id: 18,
    name: 'Large Hole Auger',
    symbol: 'LHA',
  },
  {
    id: 20,
    name: 'Roof Bolter',
    symbol: 'RB',
  },
]

export const auxiliaryAssetTypes = [
  {
    id: 16,
    name: 'Scaler',
    symbol: 'SLR',
  },
  {
    id: 17,
    name: 'Dozer',
    symbol: 'DZR',
  },
  {
    id: 21,
    name: 'Plant',
    symbol: 'PLT',
  },

  {
    id: 23,
    name: 'Locomotive',
    symbol: 'Loco',
  },
  {
    id: 25,
    name: 'Fuel Tank Truck',
    symbol: 'FTT',
  },
]
