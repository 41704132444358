import { get, concat, orderBy, groupBy, partition, compact } from 'lodash'

export const applySorting =
  (sorting = {}) =>
  tableItems => {
    const partitions = partition(tableItems, x => !!get(x, sorting.key, null))
    const sortDirection = sorting.key === 'lastUpdate' ? (sorting.asc ? 'desc' : 'asc') : sorting.asc ? 'asc' : 'desc'
    return concat(orderBy(partitions[0], [sorting.key], [sortDirection]), partitions[1])
  }

export const applyFiltering =
  (filterOptionsSelected = []) =>
  tableItems => {
    if (filterOptionsSelected.length > 0) {
      return tableItems.reduce((assetsFiltered, asset) => {
        const filterGroups = groupBy(filterOptionsSelected, '__typename')
        const notInAllGroups = Object.keys(filterGroups).some(key => {
          const filterGroup = filterGroups[key]
          let assetPath
          let filterPath
          if (key === 'MachineType') {
            assetPath = 'asset.type.id'
            filterPath = 'id'
          } else {
            throw new Error(`unhandled filter type ${key}`)
          }
          const found = filterGroup.find(filter => get(filter, filterPath) === get(asset, assetPath))
          return !found
        })
        if (!notInAllGroups) {
          assetsFiltered.push(asset)
        }
        return assetsFiltered
      }, [])
    }

    return tableItems
  }

export const applySearchQueryToRows = (rows, searchQuery = '') => {
  if (searchQuery.length >= 1) {
    return rows.filter(row => {
      const props = compact(row.cells.map(item => item.sortableValue))
      return props.some(prop =>
        typeof prop === 'string' ? prop.toLowerCase().includes(searchQuery.toLowerCase()) : prop.toString(10).includes(searchQuery),
      )
    })
  } else {
    return rows
  }
}
