var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Wrapper', {
    staticClass: "go-back-bar-wrapper"
  }, [_c('ButtonStyleless', {
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('ArrowWrapper', [_c('ChevronLeftIcon')], 1)], 1), _c('TextWrapper', {
    class: {
      large: _vm.largeTitle
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('span', {
    staticClass: "subtitle"
  }, [_vm._t("subtitle")], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }