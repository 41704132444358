<template>
  <TableWrapper>
    <TableV2
      class="assets-table"
      :title="title"
      :headers="tableHeaders"
      :rows="tableRows"
      :sorting.sync="sorting"
      :filtersAvailable="filtersAvailable"
      :filterOptionsSelected="filterOptionsSelected"
      :enableSearch="false"
      :enableFooter="false"
      :messages="messages"
      :isMobile="isMobile"
      :isLoading="isLoading"
    />
  </TableWrapper>
</template>

<script>
import { get, partition, orderBy, concat } from 'lodash'
import { DateTime } from 'luxon'
import { TABLE_SORT_TYPES } from '../../constants/settings'
import { styled } from '@egoist/vue-emotion'
import TableV2 from '@/components/Atomic/Molecules/TableV2.vue'
import TableRouterLinkCell from '../../components/Atomic/Atoms/TableCells/TableRouterLinkCell'
import { applySearchQueryToRows } from '../../utils/event/tableUtils'
import units, { convertToLocalUnitSystem } from '@/utils/units'
import { LoadingDots } from '@common/components'
import TableLabelWithToolTipCell from '@/components/Atomic/Atoms/TableCells/TableLabelWithToolTipCell'
import { formatDateAccordingToSettings } from '@/utils/time'
import fullTimeDurationFormatter from '@/utils/duration'
import assetDimensionWithDurationFormat from '@/utils/widgets/assetDimensionWithDurationFormat'
import AssetHealthStatusIcon from '@/components/Atomic/Molecules/TableAssetHealthStatusMolecule'

import permissionsMixin from '@/mixins/permissions'
import localesMixin from '@/mixins/locales'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

import ASSET_DIMENSION_QUERY from '#/graphql/assetDimensions/assetDimensions.gql'
import DRILLING_SHIFT_INSIGHT_DATA from '#/graphql/feed/drillingShiftInsightData.gql'

const TableWrapper = styled('div')`
  border-radius: 0.5rem;
  grid-area: content;
  display: grid;
  width: 100%;
  background: ${({ theme }) => theme.colors.atomic.chartCardBG};
  .assets-table {
    overflow: auto;
  }
`

export default {
  mixins: [permissionsMixin, localesMixin],
  props: {
    event: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
    assets: {
      type: Array,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  components: {
    TableWrapper,
    TableV2,
  },
  data() {
    return {
      drillingShiftInsightData: {},
      filterOptionsSelected: [],
      filtersAvailable: [],
      sorting: {
        key: 'confidenceTimestamp',
        asc: true,
      },
      tableDimensions: ['engine_hours', 'operation_time', 'idle_time', 'no_drills', 'fuel_consumption'],
      assetDimensionData: {},
      assetDimensions: [],
    }
  },
  mounted() {
    this.getMappedAssetDimensionData()
  },
  computed: {
    allDimensions() {
      return [...this.tableDimensions]
    },
    isLoading() {
      return this.$apollo.loading
    },
    title() {
      return this.$t('shiftReportEvent.tableHeader.drillingAssets')
    },
    locale() {
      return get(this.uiSettings, 'language', 'DE_DE').toLowerCase().replace('_', '-')
    },
    hasLastStatusAccess() {
      return Boolean(this.assetDetailsAccess?.lastStatus)
    },
    hasMachineStatusReadPermission() {
      return Boolean(this.machineStatusRead)
    },
    assetsMapped() {
      const assets = this.assets
      return assets.map(asset => {
        const notifications = this.event?.assetHealth.filter(f => f.assetId === asset.id)
        const errors = notifications.filter(f => f.status === 'Red')
        const warnings = notifications.filter(f => f.status === 'Yellow')
        const green = notifications.filter(f => f.status === 'Green')
        const activeNotifications = notifications ? notifications[0]?.activeNotifications : []
        let health = ''
        if (notifications.length > 0) {
          if (errors.length > 0) {
            health = 'Red'
          } else if (warnings.length > 0) {
            health = 'Yellow'
          } else if (green.length > 0) {
            health = 'Green'
          }
        }
        let healthValue = 0
        if (health === 'Red') {
          healthValue = 3 + 'error'
        } else if (health === 'Yellow') {
          healthValue = 2 + 'warning'
        } else if (health === 'Green') {
          healthValue = 1 + 'ok'
        }

        const dimensions = this.tableDimensions.map(name => {
          return {
            name,
            value: this.assetDimensionData[name]?.find(f => f.assetId === asset.id)?.floatValue || '0',
          }
        })
        return {
          ...asset,
          lastLocation: this.event?.assetLocations.find(f => f.assetId === asset.id)?.geofence || '',
          lastKnownTimeStamp: this.event?.assetLocations.find(f => f.assetId === asset.id)?.time || '',
          confidenceTimestamp: this.event?.assetConfidenceTimeStamp.find(f => f.assetId === asset.id)?.confidenceTs || '',
          notifications,
          errors,
          warnings,
          health,
          healthValue,
          dimensions,
          activeNotifications,
        }
      })
    },
    assetsSorted() {
      const partitions = partition(this.assetsMapped, x => !!get(x, this.sorting.key, null))
      const sortDirection =
        this.sorting.key === 'confidenceTimestamp' ? (this.sorting.asc ? 'desc' : 'asc') : this.sorting.asc ? 'asc' : 'desc'
      return concat(orderBy(partitions[0], [this.sorting.key], [sortDirection]), partitions[1])
    },
    tableHeaders() {
      let cols = [
        {
          id: `header_name`,
          label: this.$tc('asset', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'name',
          size: 'medium',
        },
        {
          id: 'header_last_location',
          isSortable: true,
          label: this.$tc('geofence', 1).toUpperCase(),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          sortKey: 'lastLocation',
          size: 'medium',
        },
        {
          id: 'header_last_complete_data',
          isSortable: true,
          label: this.$t('shiftReportEvent.shiftGeneralInformation.lastCompleteData').toUpperCase(),
          sortType: TABLE_SORT_TYPES.NEW_TO_OLD,
          sortKey: 'confidenceTimestamp',
          size: 'medium',
        },
        {
          id: `header_engine_hours`,
          name: 'engine_hours',
          label: this.kpiNameFinder('engine_hours'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'dimensions[0].value',
          unit: this.unitMapper('engine_hours'),
          size: 'small',
        },
        {
          id: `header_operation_time`,
          name: 'operation_time',
          label: this.kpiNameFinder('operation_time'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'dimensions[1].value',
          unit: this.unitMapper('operation_time'),
          size: 'small',
        },
        {
          id: `header_idle_time`,
          name: 'idle_time',
          label: this.kpiNameFinder('idle_time'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'dimensions[2].value',
          unit: this.unitMapper('idle_time'),
          size: 'small',
        },
        {
          id: `header_no_drills`,
          name: 'no_drills',
          label: this.kpiNameFinder('no_drills'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'dimensions[3].value',
          unit: this.unitMapper('no_drills'),
          size: 'small',
        },
        {
          id: `header_fuel_consumption`,
          name: 'fuel_consumption',
          label: this.$t(`shiftReportEvent.shiftGeneralInformation.fuelUsed`),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'dimensions[4].value',
          unit: this.unitMapper('fuel_consumption'),
          size: 'small',
        },
      ]

      const additionalColumns = [
        this.hasMachineStatusReadPermission
          ? {
              id: 'header_last_status',
              label: this.$tc('healthStatus', 1).toUpperCase(),
              sortType: TABLE_SORT_TYPES.OK_TO_ERROR,
              isSortable: true,
              sortKey: 'healthValue',
              size: 'small',
            }
          : null,
      ]
      cols.splice(1, 0, ...additionalColumns)

      return cols.filter(f => f !== null)
    },
    tableRows() {
      const rows = this.assetsSorted?.map(asset => {
        const assetId = asset?.id
        const confidenceTs = asset?.confidenceTimestamp ? this.confidenceTsValidator(asset?.confidenceTimestamp) : null
        let cells = [
          {
            id: `${assetId}_asset_name`,
            sortableValue: asset?.name,
            component: TableRouterLinkCell,
            linkName: 'AssetOverview',
            linkLabel: asset?.name,
            linkParams: { id: assetId },
            isMobilePrimarycol: true,
            mobilePrimarycolLabel: asset?.name,
            headerId: `header_name`,
          },
          {
            id: `${assetId}_last_location`,
            sortableValue: asset.lastLocation,
            label: asset.lastLocation ?? null,
            tooltipLabel: formatDateAccordingToSettings(asset?.lastKnownTimeStamp, this.uiSettings, this.selectedTimezone),
            disabled: false,
            component: this.isTableLoading ? LoadingDots : TableLabelWithToolTipCell,
            noData: this.$t('shiftReportEvent.shiftGeneralInformation.dataOutOfRange'),
            headerId: `header_last_location`,
          },
          {
            id: `${assetId}_last_complete_data`,
            sortableValue: asset?.confidenceTimestamp,
            label: confidenceTs,
            tooltipLabel: formatDateAccordingToSettings(asset?.confidenceTimestamp, this.uiSettings, this.selectedTimezone),
            disabled: !this.isConfidenceTsWithinShiftRange(asset?.confidenceTimestamp),
            component: this.isTableLoading ? LoadingDots : TableLabelWithToolTipCell,
            noData: this.$t('shiftReportEvent.shiftGeneralInformation.dataOutOfRange'),
            headerId: `header_last_complete_data`,
          },
        ]
        const additionalCells = [
          this.hasMachineStatusReadPermission
            ? {
                id: `${assetId}_last_status`,
                headerId: `header_last_status`,
                label: this.$t('health'),
                isSortable: true,
                sortableValue: asset.healthValue,
                status: asset.health,
                assetId: asset.id,
                tooltipContent: asset.activeNotifications ? asset.activeNotifications : [],
                disableNav: true,
                noData: this.$t('shiftReportEvent.shiftGeneralInformation.dataOutOfRange'),
                component: this.isTableLoading ? LoadingDots : AssetHealthStatusIcon,
              }
            : null,
        ]
        cells = cells.concat(
          this.tableDimensions.map(dimensionName => {
            const dimension = this.assetDimensions.find(f => f.name === dimensionName)
            const isDurationFormatAvailable = assetDimensionWithDurationFormat.includes(dimension?.name)
            if (dimension) {
              const unitSI = get(dimension, 'physicalUnitSI', null)
              const unitUIMetric = get(dimension, 'physicalUnitUIMetric', '')
              const unitUIImperial = get(dimension, 'physicalUnitUIImperial', null)
              const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
              const assetDimensionData = this.assetDimensionData[dimensionName]
              const value = assetDimensionData?.find(f => f.assetId === asset.id)?.floatValue || null
              const data =
                value !== null
                  ? unitSI === 's' && !this.hasDecimalFormatEnabled && isDurationFormatAvailable
                    ? fullTimeDurationFormatter(value)
                    : units(value, unitSI, unitUI, 2, false, true, false, this.thousandsSeperator, this.decimalSeperator, true)
                  : '-'
              return {
                id: dimension?.id,
                sortableValue: data,
                headerId: `header_${dimensionName}`,
              }
            } else {
              return null
            }
          }),
        )
        cells.splice(1, 0, ...additionalCells)
        return {
          rowId: `row_${assetId}`,
          cells,
        }
      })
      return applySearchQueryToRows(rows, this.searchQuery)
    },
    timeframe() {
      if (this.event?.shiftStart && this.event?.shiftEnd) {
        return {
          start: DateTime.fromISO(this.event.shiftStart),
          end: DateTime.fromISO(this.event.shiftEnd),
          shifts: [],
          granularity: 'PT1H',
          timezone: 'Europe/Berlin',
        }
      } else {
        return null
      }
    },
    messages() {
      let message = ''
      if (this.tableRows.length === 0) {
        message =
          this.searchQuery === '' ? this.$tc('shiftReportEvent.noData') : this.$t('messages.searchNoResults', { query: this.searchQuery })
      }
      return message
    },
  },
  methods: {
    async getMappedAssetDimensionData() {
      const assetDimensionData = []
      this.assetDimensionsData = assetDimensionData
    },
    unitMapper(assetDimension) {
      const dimensionData = this.assetDimensions.find(e => e.name === assetDimension)
      let unit = ''
      if (dimensionData) {
        const physicalUnitSI = get(dimensionData, 'physicalUnitSI', '')
        const physicalUnitUIMetric = get(dimensionData, 'physicalUnitUIMetric', null)
        const physicalUnitUIImperial = get(dimensionData, 'physicalUnitUIImperial', null)
        unit = convertToLocalUnitSystem(this.selectedUIUnitSystem, physicalUnitUIMetric, physicalUnitUIImperial)
        if (unit) {
          unit = unit.replace('mt', 't')
        } else {
          unit = physicalUnitSI
        }
      }
      return unit ?? ''
    },
    isConfidenceTsWithinShiftRange(ts) {
      return (
        DateTime.fromISO(ts).toMillis() >= DateTime.fromISO(this.timeframe?.start).toMillis() &&
        DateTime.fromISO(ts).toMillis() <= DateTime.fromISO(this.timeframe?.end).toMillis()
      )
    },
    confidenceTsValidator(ts) {
      if (ts) {
        if (DateTime.fromISO(ts).diff(this.timeframe.end).as('seconds') === 0) {
          return this.$t(`shiftReportEvent.shiftGeneralInformation.endOfShift`)
        } else {
          return DateTime.fromISO(ts)
            .setLocale(this.locale)
            .toRelative({ base: DateTime.fromISO(this.timeframe.end) })
        }
      }
    },
    kpiNameFinder(kpi) {
      if (!this.assetDimensions) {
        return kpi
      }
      const found = this.assetDimensions?.find(f => f.name === kpi)
      const locale = this.locale.slice(0, 2).toUpperCase()
      return found?.nameTranslations ? getAssetDimensionNameByLocale(found?.nameTranslations, locale) : kpi
    },
  },
  apollo: {
    assetDimensions: {
      query: ASSET_DIMENSION_QUERY,
      variables() {
        return {
          where: {
            name_in: this.allDimensions,
          },
        }
      },
      skip() {
        return this.allDimensions.length < 1
      },
    },
    drillingShiftInsightData: {
      query: DRILLING_SHIFT_INSIGHT_DATA,
      variables() {
        return {
          assets: {
            id_in: this.assets.map(asset => asset.id),
          },
          timeframe: this.timeframe,
        }
      },
      skip() {
        return this.assets.length < 1 && !this.timeframe.start
      },
      manual: true,
      result({ data }) {
        const assetDimensionData = {}
        assetDimensionData.operation_time = data?.operation_time?.aggrByAsset || []
        assetDimensionData.no_drills = data?.no_drills?.aggrByAsset || []
        assetDimensionData.engine_hours = data?.engine_hours?.aggrByAsset || []
        assetDimensionData.idle_time = data?.idle_time?.aggrByAsset || []
        assetDimensionData.fuel_consumption = data?.fuel_consumption?.aggrByAsset || []
        this.assetDimensionData = assetDimensionData
      },
    },
  },
}
</script>
