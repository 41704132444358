var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableWrapper', [_c('TableV2', {
    staticClass: "assets-table",
    attrs: {
      "title": _vm.title,
      "headers": _vm.tableHeaders,
      "rows": _vm.tableRows,
      "sorting": _vm.sorting,
      "filtersAvailable": _vm.filtersAvailable,
      "filterOptionsSelected": _vm.filterOptionsSelected,
      "enableSearch": false,
      "enableFooter": false,
      "messages": _vm.messages,
      "isMobile": _vm.isMobile,
      "isLoading": _vm.isLoading
    },
    on: {
      "update:sorting": function updateSorting($event) {
        _vm.sorting = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }