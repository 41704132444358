<template>
  <ShiftInsightsMainStyled>
    <ProductionOrganism
      :event="event"
      :assets="productionAssets"
      :isMobile="isMobile"
      v-if="productionAssets.length"
      :searchQuery="searchQuery"
    />
    <DrillingOrganism
      :event="event"
      :assets="drillingAssets"
      :isMobile="isMobile"
      v-if="drillingAssets.length"
      :searchQuery="searchQuery"
    />
    <AuxiliaryOrganism
      :event="event"
      :assets="auxiliaryAssets"
      :isMobile="isMobile"
      v-if="auxiliaryAssets.length"
      :searchQuery="searchQuery"
    />
  </ShiftInsightsMainStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ProductionOrganism from './ProductionOrganism.vue'
import DrillingOrganism from './DrillingOrganism.vue'
import AuxiliaryOrganism from './AuxiliaryOrganism.vue'

const ShiftInsightsMainStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
`
export default {
  inject: ['uiSettings'],
  props: {
    event: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
    assets: {
      type: Object,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  components: {
    ShiftInsightsMainStyled,
    ProductionOrganism,
    DrillingOrganism,
    AuxiliaryOrganism,
  },
  data() {
    return {}
  },
  computed: {
    productionAssets() {
      return this.assets?.productionAssets
    },
    drillingAssets() {
      return this.assets?.drillingAssets
    },
    auxiliaryAssets() {
      return this.assets?.auxiliaryAssets
    },
  },
  methods: {},
  apollo: {},
}
</script>
