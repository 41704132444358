<template>
  <Wrapper class="go-back-bar-wrapper">
    <ButtonStyleless @click="goBack()">
      <ArrowWrapper>
        <ChevronLeftIcon />
      </ArrowWrapper>
    </ButtonStyleless>
    <TextWrapper :class="{ large: largeTitle }">
      {{ title }} <span class="subtitle"><slot name="subtitle" /></span>
    </TextWrapper>
  </Wrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronLeftIcon } from 'vue-feather-icons'
import ButtonStyleless from '@/components/Atomic/Atoms/ButtonStyleless'

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 65px;
  width: 100%;
  background: ${({ theme }) => theme.colors.atomic.chartCardBG};
  border-radius: 8px;
  padding: 0 10px;
  box-sizing: border-box;
`

const ArrowWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${({ theme }) => (theme.isDark ? theme.colors.atomic.arrowWrapperBg : theme.colors.white)};
  cursor: pointer;

  & > svg {
    color: ${({ theme }) => theme.colors.atomic.primary};
  }
`

const TextWrapper = styled('div')`
  color: ${({ theme }) => theme.colors.atomic.textSecondary};
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 12px;
  &.large {
    font-size: 24px;
    text-transform: none;
    font-weight: 600;
  }
  > span {
    font-weight: 400;
  }
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    largeTitle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Wrapper,
    ArrowWrapper,
    ChevronLeftIcon,
    TextWrapper,
    ButtonStyleless,
  },
  methods: {
    goBack() {
      this.$router.back()
    },
  },
}
</script>
